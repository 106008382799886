import * as React from "react";
import { showLoader } from "../Shared/Loader";
import { deleteData, getData } from "../../utils/general-utils";
import { dbFilters, dbGetModel, tableNames } from "../../models/general-models";
import { BlogModel } from "../../models/blogs-models";
import swal from 'sweetalert';
import ConfirmModalDialog from "../Dialogs/ConfirmModalDialog";
import { baseURL } from "../../utils/server-utils";


interface Props {

}

interface State {
    getDataObj: dbGetModel;
    blogsList: BlogModel[];
    selectedBlog: BlogModel;
}

class Blog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            blogsList: [],
            getDataObj: {
                table: tableNames.blogs,
                filters: []
            },
            selectedBlog: Object()
        }
        this.fetchBlogs = this.fetchBlogs.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.fetchBlogs();
    }

    async fetchBlogs() {
        const { getDataObj } = this.state
        showLoader(true);
        await getData(getDataObj).then((res: any) => {
            if (res && res.data) {
                let resBlogs: BlogModel[] = [];
                resBlogs = res.data;
                this.setState({ blogsList: resBlogs });
            }
            showLoader(false);
        });
    }

    async handleDelete() {
        let { getDataObj, selectedBlog } = this.state
        let deleteResult: boolean = false;
        showLoader(true);
        let filters: dbFilters = {
            column_name: "id",
            operation: "=",
            value: selectedBlog.id
        }
        getDataObj.filters = [filters]
        await deleteData(getDataObj).then(res => {
            if (res && res.success) {
                swal("Success!", "Blog Deleted!", "success")
                deleteResult = true;
            }
            else {
                swal("Failed!", "Something went wrong!", "error");
                deleteResult = false
            }
            showLoader(false);
        }).catch(() => { showLoader(false); });
        if (deleteResult) {
            window.location.href = "/Blogs"
        }
    }

    render() {
        const { blogsList } = this.state;

        return (
            <React.Fragment>
                <div className="content">
                    <div className="container-fluid padding-10">
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                <div className="float-right">
                                    <button type="button" className="btn btn-primary" onClick={() => { window.location.href = "/BlogForm" }}><i className="fa fa-plus-square p-1"></i>Add new</button>
                                </div>
                            </div>


                        </div>
                        <div className="card">
                            <div className="card-body table-responsive p-0">
                                <table className="table table-head-fixed table-bordered">
                                    <thead>
                                        <tr>
                                            <th >No.</th>
                                            <th >Thumbnail</th>
                                            <th >Title</th>
                                            <th className="text-center">Published</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {blogsList && blogsList.length > 0 && blogsList.map((blog, i) => {
                                            return <tr>
                                                <td>{i + 1}</td>
                                                <td> <img src={`${baseURL}${blog.thumbnail}`} className="img-fluid" alt="thumbnailImage" style={{ height: 50 }} /></td>
                                                <td>{blog.title}</td>
                                                <td>{blog.published?.toString()}</td>
                                                <td className="text-center">
                                                    <button className="btn btn-sm btn-secondary" style={{ marginRight: "1%" }} onClick={() => { window.location.href = `/BlogForm/${blog.id}` }}><i className="fa fa-pen p-1"></i>Edit</button>
                                                    <button className="btn btn-sm btn-secondary" onClick={() => {
                                                        this.setState({ selectedBlog: blog })
                                                        document.getElementById("btnConfirmDelete")?.click();
                                                    }}><i className="fa fa-trash p-1"></i>Delete</button>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <button id="btnConfirmDelete" style={{ display: "none" }} data-toggle="modal" data-target="#ConfirmDeleteDialog" >.</button>
                    <ConfirmModalDialog id="ConfirmDeleteDialog" showCancelButton={true} cancelButtonText="No"
                        confirmButtonText="Yes" confirmationText="Are you sure to delete selected blog?"
                        heading="Confirm..."
                        onConfirmButtonClick={() => {
                            this.handleDelete();
                            this.fetchBlogs();
                        }} />

                </div>
            </React.Fragment>
        )
    }

}

export default Blog;
