import React from 'react';
import { Link } from 'react-router-dom';
export interface SidebarProps {

}

export interface SidebarState {
    urlChange: string;
}

class Sidebar extends React.Component<SidebarProps, SidebarState> {
    constructor(props: SidebarProps) {
        super(props);
        this.state = {
            urlChange: ""
        }
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.setState({ urlChange: window.location.href })
    }

    logout = () => {
        localStorage.setItem("isSignedIn", "false");
        window.location.href = "/";
    }

    handleClick = () => {
        this.setState({ urlChange: window.location.href });
    }

    render() {
        const { urlChange } = this.state
        let url = window.location.href;
        return (
            <React.Fragment>
                <aside className="main-sidebar sidebar-dark-primary elevation-4">
                    {/* Brand Logo */}
                    <a href="/Dashboard" className="brand-link">
                        <img src="/assets/dist/img/x.png" alt="admin" className="brand-image" />
                        <span className="brand-text font-weight-light">NOVATECHX</span>
                    </a>
                    {/* Sidebar */}
                    <div className="sidebar">
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                <li className="nav-item has-treeview">
                                    <Link id="Dashboard" to="/Dashboard" onClick={this.handleClick} className={`nav-link ${url.indexOf("/Dashboard") > -1 ? "active" : ""}`}>
                                        <i className="nav-icon fas fa-desktop" />
                                        <p>Dashboard</p>
                                    </Link>
                                </li>
                                <li className="nav-item has-treeview">
                                    <Link to="/Homepage" onClick={this.handleClick} className={`nav-link ${url.indexOf("/Homepage") > -1 ? "active" : ""}`}>
                                        <i className="nav-icon fas fa-home" />
                                        <p>
                                            Homepage
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item has-treeview">
                                    <Link to="/Blogs" onClick={this.handleClick} className={`nav-link ${url.indexOf("/Blogs") > -1 ? "active" : ""}`}>
                                        <i className="nav-icon fas fa-images" />
                                        <p>
                                            Blogs
                                        </p>
                                    </Link>
                                </li>

                                <li className="nav-item has-treeview">
                                    <Link to="/" className={`nav-link`} onClick={this.logout}>
                                        <i className="nav-icon fas fa-sign-out-alt" />
                                        <p>
                                            Logout
                                        </p>
                                    </Link>
                                </li>

                            </ul>
                        </nav>
                    </div>
                </aside>
            </React.Fragment>
        );
    }
}

export default Sidebar;