import axios from "axios";

export const baseURL = "https://admin.novatechx.io/nova_admin_backend/";
// export const baseURL = "http://localhost:8080/nova_admin_backend/";
// 
export async function ExecuteAPI<T>(postData: any, endPoint: string) {

    // const response = await fetch(`${baseURL}${endPoint}`, {
    //     // mode: "no-cors",
    //     method: 'POST',
    //     headers: {
    //         // "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    //         // "Accept": "application/json",
    //         "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ ...postData })
    // });
    // return await response.json().then((result) => result as T);


    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    console.log(`urls------------- ${baseURL}${endPoint}`)
    console.log("postData", postData)
    return axios.post(`${baseURL}${endPoint}`, postData)
        .then(response => {
            console.log(response);
            return response.data;
        })
        .catch(error => {
            console.log(error);
            return error;
        });
}

export async function ExecuteAPIGet<T>(endPoint: string, queryString?: string) {
    let url = "";
    if (queryString)
        url = `${baseURL}${endPoint}?${queryString}`
    else
        url = `${baseURL}${endPoint}`

    const response = await fetch(url, {
        // mode: "no-cors",
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            // "Accept": "application/json",
        },
    });
    return await response.json().then((result) => result as T);
}

export async function ExecuteAPIFile(postData: any, endPoint: string) {

    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }

    return axios.post(`${baseURL}${endPoint}`, postData, config)
        .then(response => {
            console.log(response);
            return response.data;
        })
        .catch(error => {
            console.log(error);
            return error;
        });
}