export interface credentials {
    email: string;
    password: string;
}

export interface DeleteResponse {
    ResponseCode: number;
    ResponseText: string;
}

export interface dbInsertModel {
    table: string;
    data: any
}

export interface dbUpdateModel {
    table: string;
    data: any;
    filters: dbFilters[];
}

export interface dbGetModel {
    table: string;
    filters: dbFilters[];
}

export interface dbFilters {
    column_name?: string;
    operation?: string;
    value?: string | number;
}

export const tableNames = {
    blogs: "blogs",
    homepage: "homepage",
    sponsors: "sponsors",
    partners: "partners",

}