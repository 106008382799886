import { dbGetModel, dbInsertModel, dbUpdateModel } from "../models/general-models";
import { API_EndPoints } from "./end-points";
import { ExecuteAPI, ExecuteAPIFile } from "./server-utils";

export const getBase64 = async (file): Promise<string | undefined> => {
  var reader = new FileReader();
  reader.readAsDataURL(file as Blob);

  return new Promise((reslove, reject) => {
    reader.onload = () => reslove(reader.result as any);
    reader.onerror = (error) => reject(error);
  })
}

export function resizeMe(img, max_width, max_height) {
  let canvas = document.createElement('canvas');

  let width = img.width;
  let height = img.height;

  // calculate the width and height, constraining the proportions
  if (width > height) {
    if (width > max_width) {
      //height *= max_width / width;
      height = Math.round(height *= max_width / width);
      width = max_width;
    }
  } else {
    if (height > max_height) {
      //width *= max_height / height;
      width = Math.round(width *= max_height / height);
      height = max_height;
    }
  }

  // resize the canvas and draw the image data into it
  canvas.width = width;
  canvas.height = height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, width, height);

  // preview.appendChild(canvas); // do the actual resized preview

  return canvas.toDataURL("image/jpeg", 0.8); // get the data from canvas as 70% JPG (can be also PNG, etc.)

}

// GENERIC DATA UTILS
export async function getData(request: dbGetModel) {
  return await ExecuteAPI<any>(request, API_EndPoints.General.getData);
}

export async function insertData(request: dbInsertModel) {
  JSON.stringify(request);
  return await ExecuteAPI<any>(request, API_EndPoints.General.insertData).then((res: any) => {
    if (res) return res;
  }).catch(err => { console.log("Error"); });
}

export async function uploadFile(file) {
  let formData = new FormData();    //formdata object
  formData.append('file', file);   //append the values with key, value pair
  return await ExecuteAPIFile(formData, API_EndPoints.General.uploadFile).then((res: any) => {
    if (res) return res;
  }).catch(err => { console.log("Error"); });
}

export async function updateData(request: dbUpdateModel) {
  return await ExecuteAPI<any>(request, API_EndPoints.General.updateData);
}

export async function deleteData(request: dbGetModel) {
  return await ExecuteAPI<any>(request, API_EndPoints.General.deleteData).then((res: any) => {
    if (res) return res;
  }).catch(err => { console.log("Error"); });
}


