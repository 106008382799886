export interface homepageModel {
    id: number;

    // Landing Page Section
    sec1_Title: string;
    sec1_SubHeading: string;
    sec1_BackgroundImage: string;
    sec1_GifImage: string;

    // What we offer Section
    sec2_Title: string;
    sec2_Heading: string;
    sec2_SubHeading: string;

    // What we offer Section Card 1
    sec2_Card1_Title: string;
    sec2_Card1_Description: string;
    sec2_Card1_Image: string;

    // What we offer Section Card 2
    sec2_Card2_Title: string;
    sec2_Card2_Description: string;
    sec2_Card2_Image: string;

    // Ideology Section
    sec3_Heading: string;
    sec3_SubHeading: string;
    sec3_VideoThumbnail: string;
    sec3_Video: string;

    // Collaborators & Partners Section
    sec4_Heading_Left: string;
    sec4_SubHeading: string;
    sec4_Image: string;

    sec4_Heading_Right: string;
    sec4_Partners?: partnersInfo[];

    sponsors?: sponsorsInfo[];

}

export interface partnersInfo {
    id: string;
    logo: string;
    logoColored: string;
    heading: string;
    description: string;
    highlightedDescription?: string;
    linkedIn?: string;
    website?: string;
}

export interface sponsorsInfo {
    id: string;
    logo: string;
}

export function initializePartner() {
    let partner: partnersInfo = {
        id: "",
        logo: "",
        logoColored: "",
        heading: "",
        description: "",
        highlightedDescription: "",
        linkedIn: "",
        website: ""
    }
    return partner
}

export function initializeSponsor() {
    let sponsor: sponsorsInfo = {
        id: "",
        logo: "",
    }
    return sponsor
}