export interface BlogModel {
    id: number;
    title: string;
    introduction: string;
    content: string;
    thumbnail?: string;
    mainImg?: string;
    isActive?: boolean;
    published?: Date;
    lastMod?: Date;
}

export function initializeBlog() {
    let Blog: BlogModel = {
        id: 0,
        title: "",
        introduction: "",
        content: "",
        thumbnail: "",
        mainImg: "",
        isActive: true,
        published: new Date(),
        lastMod: new Date(),
    }

    return Blog;
}

// export const editorConfiguration = {
//     toolbar: {
//         items: [
//             'alignment',
//             'heading',
//             '|',
//             'bold',
//             'italic',
//             'link',
//             'bulletedList',
//             'numberedList',
//             '|',
//             'outdent',
//             'indent',
//             '|',
//             'imageUpload',
//             'blockQuote',
//             'insertTable',
//             'mediaEmbed',
//             'undo',
//             'redo'
//         ]
//     },

//     // This value must be kept in sync with the language defined in webpack.config.js.
//     language: 'en',
//     image: {
//         toolbar: [
//             'imageTextAlternative',
//             'toggleImageCaption',
//             'imageStyle:inline',
//             'imageStyle:block',
//             'imageStyle:side'
//         ]
//     },
//     table: {
//         contentToolbar: [
//             'tableColumn',
//             'tableRow',
//             'mergeTableCells'
//         ]
//     }
// };