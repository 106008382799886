import React, { Fragment } from 'react';

export interface RightSidebarProps {

}

export interface RightSidebarState {

}

class RightSidebar extends React.Component<RightSidebarProps, RightSidebarState> {

    logout = () => {
        localStorage.setItem("isSignedIn", "false");
        window.location.href = "/";
    }

    openLanguagesDialog = () => {
        document.getElementById("btnModalChangeLanguage")?.click();
    }

    render() {
        return (
            <Fragment>
                {/* <button type="button" className="btn btn-danger ml-2" onClick={this.logout}><i className="fas fa-sign-out-alt p-1"></i>Logout</button> */}
            </Fragment>
        );
    }
}

export default RightSidebar;