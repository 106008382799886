import React, { Fragment } from 'react';

export interface DashboardProps {

}

export interface DashboardState {


}

class Dashboard extends React.Component<DashboardProps, DashboardState> {
    constructor(props: DashboardProps) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }


    render() {
        let { } = this.state;
        return (

            <Fragment>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                {/* <h1 className="m-0 text-dark">Dashboard</h1> */}
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}
                        <div className="row">
                            <div style={{ width: "100%", padding: 10, textAlign: "center", justifyContent: 'center' }}>
                                <img src="/assets/dist/img/x.png" alt="admin" className="brand-image" style={{
                                    height: 200,
                                    alignSelf: "center"
                                }} />
                                <h1 style={{ textTransform: "uppercase" }}>Welcome to NovaTechX</h1>
                                <h5>CONTENT MANAGEMENT SYSTEM</h5>
                            </div>
                        </div>
                    </div>
                </section>

            </Fragment>
        );
    }
}

export default Dashboard;