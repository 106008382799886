import * as React from "react";
import { credentials } from "../../models/general-models";
import swal from 'sweetalert';


interface Props {

}

interface State {
    Credentials: credentials;
}

class Login extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            Credentials: { password: "", email: "" }
        }
        this.handleLoginForm = this.handleLoginForm.bind(this);
        this.authenticate = this.authenticate.bind(this);
    }

    handleLoginForm(e) {
        let Credentials = this.state.Credentials;
        let name = e.target.name;
        Credentials[`${name}`] = e.target.value;
        this.setState({
            Credentials
        });

    }

    async authenticate() {
        let Credentials = this.state.Credentials;
        if (Credentials.email === "admin" && Credentials.password === "admin") {
            localStorage.setItem("isSignedIn", "true");
            window.location.href = "/Dashboard";
        }
        else {
            swal("Login Failed!", "Incorrect Email or Password!", "error"); //use error,success,info
        }
    }

    render() {
        return (
            <div className="login-page" style={{ background: "#04091e" }}>
                <div className="login-box">
                    <div className="login-logo">
                        <a href="/"><img className="app-logo" width={"100%"} src="/assets/dist/img/Logo_W.png" alt="" /></a>
                    </div>
                    <div className="card">
                        <div className="card-body login-card-body">
                            <p className="login-box-msg">Sign in to start your session</p>

                            <form>
                                <div className="input-group mb-3">
                                    <input type="email" className="form-control" required name="email" placeholder="Email" onChange={(e) => this.handleLoginForm(e)} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="password" className="form-control" required name="password" placeholder="Password" onChange={(e) => this.handleLoginForm(e)}
                                        onKeyPress={(e) => { if (e.charCode === 13) { this.authenticate() } }} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 successBtn">
                                        <button type="button" className="btn btn-success btn-block" onClick={this.authenticate}>Sign In</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;
