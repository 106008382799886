
import * as React from "react";
import CustomModalDialog from "./CustomModalDialog";

interface Props {
    id: string;
    heading?: string;
    confirmButtonText: string;
    onConfirmButtonClick: () => void;
    showCancelButton?: boolean;
    cancelButtonText?: string;
    confirmationText: string;
}

class ConfirmModalDialog extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { id, heading, confirmButtonText, onConfirmButtonClick, showCancelButton, cancelButtonText, confirmationText } = this.props;
        return (
            <CustomModalDialog id={id} onSaveButtonClick={onConfirmButtonClick} saveButtonText={confirmButtonText} size="medium"
                cancelButtonText={cancelButtonText} heading={heading} showCancelButton={showCancelButton} disableSaveButton={false}>
                <div className="row" style={{ zIndex: 9999999999 }}>
                    <div className="col-md-12">
                        <h4>{confirmationText}</h4>
                    </div>
                </div>
            </CustomModalDialog>
        )
    }
}
export default ConfirmModalDialog;
