import React from 'react';
import RightSidebar from './right-sidebar';

export interface NavbarProps {

}

export interface NavbarState {

}

class Navbar extends React.Component<NavbarProps, NavbarState> {
    render() {
        return (
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#"><i className="fas fa-bars" /></a>
                    </li>
                </ul>
                <ul className="navbar-nav ml-auto">
                    <RightSidebar />
                </ul>
            </nav>

        );
    }
}

export default Navbar;