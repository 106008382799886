
import * as React from "react";
import Dropzone from "react-dropzone";


interface Props {
    handleAttachedFile: (files: Array<file>) => void;
    acceptHeader?: string;
    files?: Array<file>;
    single?: boolean;
    dialogID?: string;
}

interface State {
    files: Array<file>

}

export interface file {
    lastModified: string;
    lastModifiedDate: string;
    name: string;
    size: string;
    type: string
    webkitRelativePath: string;
    preview: string;
    base64: string;
    file: File;
}

class AttachmentUploader extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            files: []
        }

        this.onDrop = this.onDrop.bind(this);
        this.getBase64 = this.getBase64.bind(this);
    }

    componentDidMount() {
        this.setState({ files: this.props.files })
    }

    componentWillReceiveProps(props: Props) {
        this.setState({ files: props.files ? props.files : [] })
    }

    onDrop = (files) => {
        var self = this;
        this.setState({
            files: files.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file),
                base64: self.getBase64(file),
                file: file
            }))
        }, () => {
            this.props.handleAttachedFile(this.state.files);
        });
    }

    onCancel() {
        this.setState({
            files: []
        });
    }


    getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        return reader && reader.result && reader.result.toString();
    }


    render() {
        const { files } = this.state;
        const { single = false, dialogID } = this.props;
        return (
            <>
                <Dropzone
                    onDrop={this.onDrop.bind(this)}
                    //  accept="image/jpeg, image/png, video/mp4"
                    accept={this.props.acceptHeader ? this.props.acceptHeader : null}
                    onFileDialogCancel={this.onCancel.bind(this)}
                    multiple={!single}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {files && files.length > 0 ?
                                <div className="row padding-10">
                                    {files.length > 0 && files.map((file, i) => {
                                        return <div key={i} className="col-md-3">
                                            {(file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif") && <img alt="" className="img-fluid" src={file.preview} />}
                                            {(file.type === "video/mp4") && <video width="220" height="140">
                                                <source src={file.preview} type="video/mp4" />
                                            </video>
                                            }
                                            {((file.type !== "image/jpeg") && (file.type !== "image/png")) && <label>{file.name}</label>}
                                        </div>
                                    })}
                                </div> :
                                <div className="upload-div" id={`${dialogID ? dialogID : `dropzone`}`}>
                                    <p>Drop file here, or click to select file</p>
                                    <i className="fa fa-upload icn-upload" aria-hidden="true"></i>
                                </div>
                            }
                        </div>
                    )}
                </Dropzone>

            </>
        )
    }
}
export default AttachmentUploader
