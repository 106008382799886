import * as React from "react";
import { showLoader } from "../Shared/Loader";
import swal from 'sweetalert';
import { getData, insertData, updateData, uploadFile } from "../../utils/general-utils";
import { dbFilters, dbGetModel, dbInsertModel, dbUpdateModel, tableNames } from "../../models/general-models";
import { BlogModel, initializeBlog } from "../../models/blogs-models";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AttachmentUploader, { file } from "../Shared/AttachmentUploader";
import { useParams } from 'react-router-dom';
import { baseURL } from "../../utils/server-utils";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}


interface Props {
    id?: number;
    params?: any;
}

interface State {
    files: Array<file>,
    thumbnailImage: file,
    mainImage: file,
    isEdit: boolean;
    Blog: BlogModel;
}

class BlogForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            files: [],
            thumbnailImage: Object(),
            mainImage: Object(),
            isEdit: false,
            Blog: Object(),
        }

        this.handleBlogForm = this.handleBlogForm.bind(this);
        this.fetchBlog = this.fetchBlog.bind(this);
        this.saveBlog = this.saveBlog.bind(this);
        this.handleSaveImages = this.handleSaveImages.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.handlefileAttached = this.handlefileAttached.bind(this);
    }

    componentDidMount() {
        let { id } = this.props.params;
        this.fetchBlog(id);
    }

    async fetchBlog(id?: string) {
        let { mainImage, thumbnailImage } = this.state
        if (id) {
            showLoader(true);
            let filters: dbFilters = {
                column_name: "id",
                operation: "=",
                value: id
            }
            const getDataObj: dbGetModel = {
                table: tableNames.blogs,
                filters: [filters]
            }
            let res = await getData(getDataObj)
            if (res && res.data && res.data.length > 0) {
                let Blog: BlogModel = Object();
                Blog = res.data[0];
                // if (Blog.mainImg) {
                //     mainImage = this.handleFileModel(Blog.mainImg)
                // }
                // if (Blog.thumbnail) {
                //     thumbnailImage = this.handleFileModel(Blog.thumbnail)
                // }
                this.setState({ Blog, mainImage, thumbnailImage, isEdit: true });
            }
            showLoader(false);
        } else {
            this.setState({ Blog: initializeBlog(), isEdit: false })
        }
    }

    // handleFileModel(imgText: string) {
    //     let tempFileData: file = Object();
    //     fetch("http://localhost:8080/nova_admin_backend/" + imgText)
    //         .then((res) => res.blob())
    //         .then((myBlob) => {
    //             const myFile = new File([myBlob], 'image.jpeg', { type: myBlob.type });
    //             tempFileData = {
    //                 lastModified: "",
    //                 lastModifiedDate: "",
    //                 name: "",
    //                 size: "",
    //                 type: "", webkitRelativePath: "",
    //                 preview: "",
    //                 base64: "",
    //                 file: myFile
    //             }
    //         });
    //     return tempFileData;
    // }

    async saveBlog() {
        let { Blog, isEdit } = this.state;
        const { mainImage, thumbnailImage } = this.state
        showLoader(true);
        if (!isEdit) {

            if (mainImage) {
                Blog.mainImg = await this.handleSaveImages(mainImage)
            }
            if (thumbnailImage) {
                Blog.thumbnail = await this.handleSaveImages(thumbnailImage)
            }

            Blog.isActive = true;
            Blog.published = new Date();
            Blog.lastMod = new Date();

            const insertDataObj: dbInsertModel = {
                table: tableNames.blogs,
                data: Blog
            }
            await insertData(insertDataObj).then(res => {
                if (res) {
                    swal("Success!", "Blog Added!", "success");
                    window.location.href = "/Blogs"
                }
                else
                    swal("Failed!", "Something went wrong!", "error");
                showLoader(false);
            }).catch(() => { showLoader(false); });
        } else {
            let filters: dbFilters = {
                column_name: "id",
                operation: "=",
                value: this.props.id ? this.props.id : Blog.id ? Blog.id : ""
            }
            if (mainImage && mainImage.file) {
                Blog.mainImg = await this.handleSaveImages(mainImage)
            }
            if (thumbnailImage && thumbnailImage.file) {
                Blog.thumbnail = await this.handleSaveImages(thumbnailImage)
            }

            Blog.lastMod = new Date();
            const updateDataObj: dbUpdateModel = {
                table: tableNames.blogs,
                data: Blog,
                filters: [filters]
            }
            await updateData(updateDataObj).then(res => {
                if (res) {
                    swal("Success!", "Blog Updated!", "success");
                    window.location.href = "/Blogs"
                }
                else
                    swal("Failed!", "Something went wrong!", "error");
                showLoader(false);
            }).catch(() => { showLoader(false); });;
        }
        showLoader(false);
    }

    async handleSaveImages(rawImage: file) {
        let filePath: string = "";
        if (rawImage) {
            let imageObj: File = rawImage.file
            let res = await uploadFile(imageObj);
            if (res && res.file_path) {
                filePath = res.file_path
            }
        }
        return filePath;
    }

    handleBlogForm(event) {
        let Blog = this.state.Blog;
        let name = event.target.name;
        Blog[`${name}`] = event.target.value;
        this.setState({ Blog });
    }

    handlefileAttached(file: Array<file>, type?: string) {
        let { mainImage, thumbnailImage, Blog } = this.state
        if (file.length > 0) {
            let self = this;
            let image = new Image();
            image.src = file[0].preview;
            image.onload = function () {
                if (type === "main") {
                    Blog.mainImg = "";
                    mainImage = file[0]
                    Blog.mainImg = file[0].name
                } else {
                    Blog.thumbnail = "";
                    thumbnailImage = file[0]
                    Blog.thumbnail = file[0].name
                }
                self.setState({ mainImage, thumbnailImage, Blog });
            }
        }
    }

    onChangeHandler(editor) {
        let { Blog } = this.state;
        var newContent = editor.getData();
        Blog.content = newContent;
        this.setState({
            Blog
        });
    }

    render() {
        const { Blog, mainImage, thumbnailImage, files } = this.state;
        let isValid: boolean = (Blog.title && Blog.introduction && Blog.thumbnail && Blog.mainImg && Blog.content) ? true : false
        return (

            <React.Fragment>
                <div className="content">
                    <div className="container-fluid padding-1Product0">
                        <div className="col-md-12 padding-10">
                            <button className="btn btn-secondary" onClick={() => { window.location.href = "/Blogs" }}><i className="fa fa-arrow-circle-left"></i> Back</button>
                            <button disabled={!isValid} className="btn btn-primary float-right ml-2" onClick={() => { this.saveBlog() }}><i className="fa fa-save"></i> Save</button>
                        </div>
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Blog Form</h3>
                            </div>
                            <div className="card-body">

                                <div className="form-group row form-horizontal">
                                    <div className="col-md-2 col-form-label">
                                        <div className="label-wrapper">
                                            <label htmlFor="title">Title</label>
                                            <div title="The title for the blog" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                        </div>
                                    </div>
                                    <div className="col-md-10"><input type="text" className="form-control" name="title" value={Blog.title} id="title" placeholder="title" onChange={(e) => this.handleBlogForm(e)} style={{ borderRadius: "0px !important" }} /></div>
                                </div>
                                <div className="form-group row form-horizontal">
                                    <div className="col-md-2 col-form-label">
                                        <div className="label-wrapper">
                                            <label htmlFor="introduction">Introduction</label>
                                            <div title="Introduction of the blog, 150 characters max!" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                        </div>
                                    </div>
                                    <div className="col-md-10"><textarea maxLength={150} className="form-control" value={Blog.introduction} name="introduction" id="introduction" placeholder="Introduction" onChange={(e) => this.handleBlogForm(e)} /></div>
                                </div>
                                {/* <div className="form-group row form-horizontal">
                                    <div className="col-md-2 col-form-label">
                                        <div className="label-wrapper">
                                            <label htmlFor="description">Description</label>
                                            <div title="Detailed description of the blog" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                        </div>
                                    </div>
                                    <div className="col-md-10"><textarea className="form-control" value={Blog.description} name="description" id="description" placeholder="Description" onChange={(e) => this.handleBlogForm(e)} /></div>
                                </div> */}

                                <div className="form-group row form-horizontal">
                                    <div className="col-md-2 col-form-label">
                                        <div className="label-wrapper">
                                            <label className="" htmlFor="Published">Upload Thumbnail Image</label>
                                            <div title="Thumbnail Image" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                        </div>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="form-group">
                                            <AttachmentUploader handleAttachedFile={(e) => {
                                                this.handlefileAttached(e, "thumb")
                                            }} files={thumbnailImage && Object.keys(thumbnailImage).length > 0 ? [thumbnailImage] : files} single={true} dialogID="thumbnailImage"></AttachmentUploader>
                                        </div>
                                        <div className="form-group">
                                            <img src={Blog.thumbnail ? baseURL + Blog.thumbnail : ""} width={150} />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row form-horizontal">
                                    <div className="col-md-2 col-form-label">
                                        <div className="label-wrapper">
                                            <label className="" htmlFor="Published">Upload Main Image</label>
                                            <div title="Main Image" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                        </div>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="form-group">
                                            <AttachmentUploader handleAttachedFile={(e) => {
                                                this.handlefileAttached(e, "main")
                                            }} files={mainImage && Object.keys(mainImage).length > 0 ? [mainImage] : files} single={true} dialogID="mainImage"></AttachmentUploader>
                                        </div>
                                        <div className="form-group">
                                            <img src={Blog.mainImg ? baseURL + Blog.mainImg : ""} width={150} />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row form-horizontal" style={{ paddingBottom: "10%" }}>
                                    <div className="col-md-2 col-form-label">
                                        <div className="label-wrapper">
                                            <label className="" htmlFor="Content">Blog Content</label>
                                            <div title="Blog Content" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                        </div>
                                    </div>
                                    <div className="col-md-10">
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={Blog.content ? Blog.content : "<h1>Start writing content for the blog here!</h1>"}
                                            config={{
                                                ckfinder: {
                                                    uploadUrl: "http://localhost:8080/new-admin/public/assets/fileHandler/upload.php"
                                                },
                                            }}
                                            onReady={editor => {
                                                console.log('Editor is ready to use!', editor);
                                            }}
                                            onChange={(event, editor) => this.onChangeHandler(editor)}
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }

}

export default withParams(BlogForm);

