import * as React from "react";
import swal from 'sweetalert';
import { homepageModel, initializePartner, initializeSponsor, partnersInfo, sponsorsInfo } from "../../models/homepage-models";
import { dbFilters, dbGetModel, dbInsertModel, dbUpdateModel, tableNames } from "../../models/general-models";
import { showLoader } from "../Shared/Loader";
import { deleteData, getData, insertData, updateData, uploadFile } from "../../utils/general-utils";
import AttachmentUploader, { file } from "../Shared/AttachmentUploader";
import { baseURL } from "../../utils/server-utils";
import CustomModalDialog from "../Dialogs/CustomModalDialog";
import ConfirmModalDialog from "../Dialogs/ConfirmModalDialog";

interface Props {

}

interface State {
    homepageObj: homepageModel;
    partnersList: partnersInfo[];
    selectedPartner: partnersInfo;
    sponsorsList: sponsorsInfo[];
    selectedSponsor: sponsorsInfo;

    // For IMAGES
    files: Array<file>,
    homepageImage: file;
    card1Image: file;
    card2Image: file;
    videoThumbnail: file;
    section4Image: file;
    partnersImage: file;
    partnersImageColored: file;
    sponsorsImage: file;

    isPartnerEdit: boolean;
    isHomepageEdit: boolean;
}

class Homepage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            homepageObj: Object(),
            partnersList: [],
            selectedPartner: Object(),
            sponsorsList: [],
            selectedSponsor: Object(),

            files: [],
            homepageImage: Object(),
            card1Image: Object(),
            card2Image: Object(),
            videoThumbnail: Object(),
            section4Image: Object(),
            partnersImage: Object(),
            partnersImageColored: Object(),
            sponsorsImage: Object(),

            isPartnerEdit: false,
            isHomepageEdit: false,
        }
        this.fetchHomepage = this.fetchHomepage.bind(this);
        this.fetchPartners = this.fetchPartners.bind(this);
        this.fetchSponsors = this.fetchSponsors.bind(this);
        this.handleHomepageChange = this.handleHomepageChange.bind(this);
    }

    async componentDidMount() {
        await this.getCompnenetData();
    }

    async getCompnenetData() {
        let homepageObj: homepageModel = Object();
        homepageObj = await this.fetchHomepage();
        homepageObj = await this.fetchSponsors(homepageObj);
        homepageObj = await this.fetchPartners(homepageObj);
        this.setState({ homepageObj });
    }

    async fetchHomepage() {
        let homepageObj: homepageModel = Object();
        let getDataObj: dbGetModel = {
            table: tableNames.homepage,
            filters: []
        }
        const res = await getData(getDataObj)
        if (res && res.data && res.data[0])
            homepageObj = res.data[0];
        return homepageObj;
    }

    async saveHomepage() {
        let { homepageObj, isHomepageEdit } = this.state
        showLoader(true);
        delete homepageObj.sponsors
        delete homepageObj.sec4_Partners
        if (isHomepageEdit) {
            let filters: dbFilters = {
                column_name: "id",
                operation: "=",
                value: homepageObj.id
            }

            const updateDataObj: dbUpdateModel = {
                table: tableNames.homepage,
                data: homepageObj,
                filters: [filters]
            }

            await updateData(updateDataObj).then(res => {
                if (res && res.data) {
                    homepageObj = res.data
                    swal("Success!", "Homepage Updated!", "success");
                }
                else
                    swal("Failed!", "Something went wrong!", "error");
                showLoader(false);
            }).catch(() => { showLoader(false); });;

        } else {
            const insertDataObj: dbInsertModel = {
                table: tableNames.homepage,
                data: homepageObj
            }
            await insertData(insertDataObj).then(res => {
                if (res && res.data) {
                    homepageObj = res.data
                    swal("Success!", "Homepage Added!", "success");
                }
                else
                    swal("Failed!", "Something went wrong!", "error");
                showLoader(false);
            }).catch(() => { showLoader(false); });
        }

        this.setState({ homepageObj })
        await this.getCompnenetData();
    }

    async fetchSponsors(homepageObj: homepageModel) {
        let getDataObj: dbGetModel = {
            table: tableNames.sponsors,
            filters: []
        }
        const resSponsors = await getData(getDataObj)
        if (resSponsors && resSponsors.data) {
            homepageObj.sponsors = resSponsors.data;
            this.setState({ sponsorsList: resSponsors.data });
        }
        return homepageObj;
    }

    async fetchPartners(homepageObj: homepageModel) {
        let getDataObj: dbGetModel = {
            table: tableNames.partners,
            filters: []
        }
        const resPartners = await getData(getDataObj)
        if (resPartners && resPartners.data) {
            homepageObj.sec4_Partners = resPartners.data;
            this.setState({ partnersList: resPartners.data });
        }
        return homepageObj;
    }

    handleHomepageChange(event) {
        let homepageObj = this.state.homepageObj;
        let name = event.target.name;
        homepageObj[`${name}`] = event.target.value;
        this.setState({ homepageObj, isHomepageEdit: true });
    }

    handlefileAttached(file: Array<file>, name?: string, type?: string) {
        let { homepageObj, selectedPartner, selectedSponsor, partnersImage, partnersImageColored, sponsorsImage, card1Image, card2Image, homepageImage, section4Image, videoThumbnail } = this.state
        if (file.length > 0) {
            let self = this;
            let image = new Image();
            image.src = file[0].preview;
            image.onload = function () {
                if (type === "partner") {
                    if (name === "logo") {
                        partnersImage = file[0];
                        selectedPartner.logo = "";
                        selectedPartner.logo = file[0].name;
                    } else if (name === "logoColored") {
                        partnersImageColored = file[0];
                        selectedPartner.logoColored = "";
                        selectedPartner.logoColored = file[0].name;
                    }
                } else if (type === "sponsor") {
                    sponsorsImage = file[0];
                    selectedSponsor.logo = "";
                    selectedSponsor.logo = file[0].name;
                } else if (type === "card1") {
                    card1Image = file[0]
                    homepageObj[`${name}`] = "";
                    homepageObj[`${name}`] = file[0].name;
                } else if (type === "card2") {
                    card2Image = file[0]
                    homepageObj[`${name}`] = "";
                    homepageObj[`${name}`] = file[0].name;
                } else if (type === "homeImage") {
                    homepageImage = file[0]
                    homepageObj[`${name}`] = "";
                    homepageObj[`${name}`] = file[0].name;
                } else if (type === "videoThumbnail") {
                    videoThumbnail = file[0]
                    homepageObj[`${name}`] = "";
                    homepageObj[`${name}`] = file[0].name;
                } else if (type === "sec4Image") {
                    section4Image = file[0]
                    homepageObj[`${name}`] = "";
                    homepageObj[`${name}`] = file[0].name;
                } else {
                    homepageObj[`${name}`] = "";
                    homepageObj[`${name}`] = file[0].name;
                }
                self.setState({ homepageObj, selectedPartner, selectedSponsor, partnersImage, partnersImageColored, sponsorsImage, card1Image, card2Image, homepageImage, section4Image, videoThumbnail });
            }
        }
    }

    // PARTNER FUNCTIONS
    handlePartnerEditClick(selectedPartner: partnersInfo) {
        this.setState({ selectedPartner, isPartnerEdit: true }, () => {
            document.getElementById("btnPartnerForm").click();
        })
    }

    handlePartnerChange(event) {
        let selectedPartner = this.state.selectedPartner;
        let name = event.target.name;
        selectedPartner[`${name}`] = event.target.value;
        this.setState({ selectedPartner });
    }

    handleNewPartner() {
        this.setState({ selectedPartner: initializePartner() }, () => {
            document.getElementById("btnPartnerForm").click();
        })
    }

    async savePartner() {
        let { selectedPartner, partnersList, isPartnerEdit, partnersImage, partnersImageColored, homepageObj } = this.state
        showLoader(true);
        if (partnersImage && partnersImage.file) {
            selectedPartner.logo = await this.handleSaveImages(partnersImage)
        }

        if (partnersImageColored && partnersImageColored.file) {
            selectedPartner.logoColored = await this.handleSaveImages(partnersImageColored)
        }
        if (isPartnerEdit) {
            let filters: dbFilters = {
                column_name: "id",
                operation: "=",
                value: selectedPartner.id
            }

            const updateDataObj: dbUpdateModel = {
                table: tableNames.partners,
                data: selectedPartner,
                filters: [filters]
            }

            await updateData(updateDataObj).then(res => {
                if (res) {
                    partnersList.splice(partnersList.findIndex((x) => (x.id === selectedPartner.id)), 1, selectedPartner)
                    this.setState({ partnersList })
                    swal("Success!", "Partner Updated!", "success");
                }
                else
                    swal("Failed!", "Something went wrong!", "error");
                showLoader(false);
            }).catch(() => { showLoader(false); });;

        } else {
            partnersList.push(selectedPartner)
            const insertDataObj: dbInsertModel = {
                table: tableNames.partners,
                data: selectedPartner
            }
            await insertData(insertDataObj).then(res => {
                if (res) {
                    swal("Success!", "Partner Added!", "success");
                }
                else
                    swal("Failed!", "Something went wrong!", "error");
                showLoader(false);
            }).catch(() => { showLoader(false); });
        }

        homepageObj.sec4_Partners = partnersList
        this.setState({ selectedPartner: Object(), partnersList, homepageObj, isHomepageEdit: true })
    }

    async handleDeletePartner() {
        let { selectedPartner, partnersList, homepageObj } = this.state
        showLoader(true);
        let filters: dbFilters = {
            column_name: "id",
            operation: "=",
            value: selectedPartner.id
        }
        const deleteDataObj: dbGetModel = {
            table: tableNames.partners,
            filters: [filters]
        }
        await deleteData(deleteDataObj).then(res => {
            if (res && res.success) {
                if (partnersList && partnersList.length > 0) {
                    partnersList.splice(partnersList.findIndex((x) => (x.id === selectedPartner.id)), 1)
                    homepageObj.sec4_Partners = partnersList
                    this.setState({ partnersList, homepageObj, isHomepageEdit: true })
                }
                swal("Success!", "Partner Deleted!", "success");

            } else
                swal("Failed!", "Something went wrong!", "error");
            showLoader(false);
        }).catch(() => { showLoader(false); });

    }

    // SPONSOR FUNCTIONS
    handleSponsorEditClick(selectedSponsor: sponsorsInfo) {
        this.setState({ selectedSponsor }, () => {
            document.getElementById("btnSponsorForm").click();
        })
    }

    handleSponsorChange(event) {
        let selectedSponsor = this.state.selectedSponsor;
        let name = event.target.name;
        selectedSponsor[`${name}`] = event.target.value;
        this.setState({ selectedSponsor });
    }

    handleNewSponsor() {
        this.setState({ selectedSponsor: initializeSponsor() }, () => {
            document.getElementById("btnSponsorForm").click();
        })
    }

    async saveSponsor() {
        let { selectedSponsor, sponsorsList, sponsorsImage, homepageObj } = this.state
        sponsorsList.push(selectedSponsor)

        if (sponsorsImage && sponsorsImage.file) {
            selectedSponsor.logo = await this.handleSaveImages(sponsorsImage)
        }

        const insertDataObj: dbInsertModel = {
            table: tableNames.sponsors,
            data: selectedSponsor
        }

        await insertData(insertDataObj).then(res => {
            if (res) {
                swal("Success!", "Sponsor Added!", "success");
            }
            else
                swal("Failed!", "Something went wrong!", "error");
            showLoader(false);
        }).catch(() => { showLoader(false); });

        homepageObj.sponsors = sponsorsList
        this.setState({ selectedSponsor: Object(), sponsorsList, homepageObj, isHomepageEdit: true })
    }

    async handleDeleteSponsor() {
        let { selectedSponsor, sponsorsList, homepageObj } = this.state
        showLoader(true);
        let filters: dbFilters = {
            column_name: "id",
            operation: "=",
            value: selectedSponsor.id
        }
        const deleteDataObj: dbGetModel = {
            table: tableNames.sponsors,
            filters: [filters]

        }
        await deleteData(deleteDataObj).then(res => {
            if (res && res.success) {
                if (sponsorsList && sponsorsList.length > 0) {
                    sponsorsList.splice(sponsorsList.findIndex((x) => (x.id === selectedSponsor.id)), 1)
                    homepageObj.sponsors = sponsorsList
                    this.setState({ sponsorsList, isHomepageEdit: true })
                }
                swal("Success!", "Sponsor Deleted!", "success");

            } else
                swal("Failed!", "Something went wrong!", "error");
            showLoader(false);
        }).catch(() => { showLoader(false); });

    }

    async handleSaveImages(rawImage: file) {
        let filePath: string = "";
        if (rawImage) {
            let imageObj: File = rawImage.file
            let res = await uploadFile(imageObj);
            if (res && res.file_path) {
                filePath = res.file_path
            }
        }
        return filePath;
    }

    async handleSectionImageUpload(rawImage: file, section: string) {
        showLoader(true)
        let { homepageObj } = this.state
        homepageObj[section] = await this.handleSaveImages(rawImage);
        this.setState({ homepageObj, isHomepageEdit: true })
        showLoader(false)
    }

    render() {
        const { homepageObj, partnersList, selectedPartner, selectedSponsor, sponsorsList, homepageImage, card1Image, card2Image, videoThumbnail, section4Image, partnersImage, partnersImageColored, sponsorsImage, files } = this.state;
        let isValid: boolean = (homepageObj && homepageObj.sec1_BackgroundImage && homepageObj.sec1_Title && homepageObj.sec1_SubHeading && homepageObj.sec2_Title && homepageObj.sec2_Heading && homepageObj.sec2_SubHeading && homepageObj.sec2_Card1_Title && homepageObj.sec2_Card1_Description && homepageObj.sec2_Card1_Image && homepageObj.sec2_Card2_Title && homepageObj.sec2_Card2_Description && homepageObj.sec2_Card2_Image && homepageObj.sec3_Heading && homepageObj.sec3_SubHeading && homepageObj.sec3_VideoThumbnail && homepageObj.sec3_Video && homepageObj.sec4_Heading_Left && homepageObj.sec4_SubHeading && homepageObj.sec4_Image && homepageObj.sec4_Heading_Right) ? true : false
        return (
            <React.Fragment>
                <div className="content">
                    <div className="container-fluid padding-1Product0">
                        <div className="col-md-12 padding-10" style={{ display: "flow-root" }}>
                            <button
                                disabled={!isValid}
                                onClick={() => { this.saveHomepage() }}
                                className="btn btn-primary float-right ml-2 mb-2"
                            ><i className="fa fa-save"></i> Save</button>
                        </div>
                        <div className="col-md-12">
                            <div className="card card-primary w-100">
                                <div className="card-header">
                                    <h3 className="card-title">Homepage Form</h3>
                                </div>
                                <div className="card-body">

                                    <div className="col-md-12 headingDivStyle">
                                        <h5 className="headingStyle">Section 1</h5>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label htmlFor="sec1_Title">Landing Page Title</label>
                                                <div title="Title for the landing page" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10"><input type="text" className="form-control" name="sec1_Title"
                                            value={homepageObj.sec1_Title}
                                            onChange={(e) => this.handleHomepageChange(e)}
                                            id="sec1_Title" placeholder="Landing page title"
                                            style={{ borderRadius: "0px !important" }} /></div>
                                    </div>
                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label htmlFor="sec1_SubHeading">Introduction</label>
                                                <div title="Introduction on the landing page, 150 characters max!" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10"><textarea className="form-control"
                                            value={homepageObj.sec1_SubHeading}
                                            onChange={(e) => this.handleHomepageChange(e)}
                                            name="sec1_SubHeading" id="sec1_SubHeading" placeholder="Introduction"
                                        /></div>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label className="" htmlFor="sec1_BackgroundImage">Image</label>
                                                <div title="Landing page image" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="form-group">
                                                <AttachmentUploader handleAttachedFile={(e) => {
                                                    this.handlefileAttached(e, "sec1_BackgroundImage", "homeImage")
                                                }} files={homepageImage && Object.keys(homepageImage).length > 0 ? [homepageImage] : files} single={true} dialogID="sec1_BackgroundImage"></AttachmentUploader>
                                            </div>
                                            <div className="form-group">
                                                <img src={homepageObj.sec1_BackgroundImage ? baseURL + homepageObj.sec1_BackgroundImage : ""} width={150} />
                                            </div>

                                            {homepageImage && Object.keys(homepageImage).length > 0 && <button className="btn btn-primary float-right ml-2 mb-2"
                                                onClick={() => {
                                                    this.handleSectionImageUpload(homepageImage, "sec1_BackgroundImage")
                                                    this.setState({ homepageImage: Object() })
                                                }}><i className="fa fa-save"></i> Upload Image</button>}
                                        </div>
                                    </div>

                                    {/***************Section 2***************/}
                                    <div className="col-md-12 headingDivStyle">
                                        <h5 className="headingStyle">Section 2</h5>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label htmlFor="sec2_Title">Title</label>
                                                <div title="Title of section 2" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10"><input maxLength={150} className="form-control"
                                            value={homepageObj.sec2_Title}
                                            onChange={(e) => this.handleHomepageChange(e)}
                                            name="sec2_Title" id="sec2_Title" placeholder="Title"
                                        /></div>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label htmlFor="sec2_Heading">Heading</label>
                                                <div title="Heading of section 2" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10"><input maxLength={150} className="form-control"
                                            value={homepageObj.sec2_Heading}
                                            onChange={(e) => this.handleHomepageChange(e)}
                                            name="sec2_Heading" id="sec2_Heading" placeholder="Heading"
                                        /></div>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label htmlFor="sec2_SubHeading">Sub-Heading</label>
                                                <div title="Sub-heading of section 2" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10"><textarea className="form-control"
                                            value={homepageObj.sec2_SubHeading}
                                            onChange={(e) => this.handleHomepageChange(e)}
                                            name="sec2_SubHeading" id="sec2_SubHeading" placeholder="Sub-heading"
                                        /></div>
                                    </div>

                                    {/***************Section 2 Card 1***************/}
                                    <div className="col-md-12 headingDivStyle">
                                        <h6 className="headingStyle">Card 1 (Left)</h6>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label htmlFor="sec2_Card1_Title">Title</label>
                                                <div title="Title of card 1 (Left)" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10"><input maxLength={150} className="form-control"
                                            value={homepageObj.sec2_Card1_Title}
                                            onChange={(e) => this.handleHomepageChange(e)}
                                            name="sec2_Card1_Title" id="sec2_Card1_Title" placeholder="Title"
                                        /></div>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label htmlFor="sec2_Card1_Description">Description</label>
                                                <div title="Description of card 1 (Left)" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10"><textarea className="form-control"
                                            value={homepageObj.sec2_Card1_Description}
                                            onChange={(e) => this.handleHomepageChange(e)}
                                            name="sec2_Card1_Description" id="sec2_Card1_Description" placeholder="Description"
                                        /></div>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label className="" htmlFor="Published">Image</label>
                                                <div title="Image of card 1 (left)" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="form-group">
                                                <AttachmentUploader handleAttachedFile={(e) => {
                                                    this.handlefileAttached(e, "sec2_Card1_Image", "card1")
                                                }} files={card1Image && Object.keys(card1Image).length > 0 ? [card1Image] : files} single={true} dialogID="sec2_Card1_Image"></AttachmentUploader>
                                            </div>
                                            <div className="form-group">
                                                <img src={homepageObj.sec2_Card1_Image ? baseURL + homepageObj.sec2_Card1_Image : ""} width={150} />
                                            </div>

                                            {card1Image && Object.keys(card1Image).length > 0 && <button className="btn btn-primary float-right ml-2 mb-2"
                                                onClick={() => {
                                                    this.handleSectionImageUpload(card1Image, "sec2_Card1_Image")
                                                    this.setState({ card1Image: Object() })
                                                }}><i className="fa fa-save"></i> Upload Image</button>}
                                        </div>
                                    </div>

                                    {/***************Section 2 Card 2***************/}
                                    <div className="col-md-12 headingDivStyle">
                                        <h6 className="headingStyle">Card 2 (Right)</h6>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label htmlFor="sec2_Card2_Title">Title</label>
                                                <div title="Title of card 2 (Right)" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10"><input maxLength={150} className="form-control"
                                            value={homepageObj.sec2_Card2_Title}
                                            onChange={(e) => this.handleHomepageChange(e)}
                                            name="sec2_Card2_Title" id="sec2_Card2_Title" placeholder="Title"
                                        /></div>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label htmlFor="sec2_Card2_Description">Description</label>
                                                <div title="Description of card 2 (Right)" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10"><textarea className="form-control"
                                            value={homepageObj.sec2_Card2_Description}
                                            onChange={(e) => this.handleHomepageChange(e)}
                                            name="sec2_Card2_Description" id="sec2_Card2_Description" placeholder="Description"
                                        /></div>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label className="" htmlFor="Published">Image</label>
                                                <div title="Image of card 2 (Right)" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="form-group">
                                                <AttachmentUploader handleAttachedFile={(e) => {
                                                    this.handlefileAttached(e, "sec2_Card2_Image", "card2")
                                                }} files={card2Image && Object.keys(card2Image).length > 0 ? [card2Image] : files} single={true} dialogID="sec2_Card2_Image"></AttachmentUploader>
                                            </div>
                                            <div className="form-group">
                                                <img src={homepageObj.sec2_Card2_Image ? baseURL + homepageObj.sec2_Card2_Image : ""} width={150} />
                                            </div>
                                            {card2Image && Object.keys(card2Image).length > 0 && <button className="btn btn-primary float-right ml-2 mb-2"
                                                onClick={() => {
                                                    this.handleSectionImageUpload(card2Image, "sec2_Card2_Image")
                                                    this.setState({ card2Image: Object() })
                                                }}><i className="fa fa-save"></i> Upload Image</button>}
                                        </div>
                                    </div>

                                    {/***************Section 3***************/}
                                    <div className="col-md-12 headingDivStyle">
                                        <h5 className="headingStyle">Section 3</h5>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label htmlFor="sec3_Heading">Heading</label>
                                                <div title="Heading of section 3" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10"><input maxLength={150} className="form-control"
                                            value={homepageObj.sec3_Heading}
                                            onChange={(e) => this.handleHomepageChange(e)}
                                            name="sec3_Heading" id="sec3_Heading" placeholder="Heading"
                                        /></div>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label htmlFor="sec3_SubHeading">Sub-Heading</label>
                                                <div title="Sub-Heading of section 3" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10"><textarea className="form-control"
                                            value={homepageObj.sec3_SubHeading}
                                            onChange={(e) => this.handleHomepageChange(e)}
                                            name="sec3_SubHeading" id="sec3_SubHeading" placeholder="Sub-Heading"
                                        /></div>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label htmlFor="sec3_Video">Video URL</label>
                                                <div title="Video URL of section 3" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10"><input maxLength={150} className="form-control"
                                            value={homepageObj.sec3_Video}
                                            onChange={(e) => this.handleHomepageChange(e)}
                                            name="sec3_Video" id="sec3_Video" placeholder="Video URL"
                                        /></div>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label className="" htmlFor="Published">Video Thumbnail</label>
                                                <div title="Video Thumbnail" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="form-group">
                                                <AttachmentUploader handleAttachedFile={(e) => {
                                                    this.handlefileAttached(e, "sec3_VideoThumbnail", "videoThumbnail")
                                                }} files={videoThumbnail && Object.keys(videoThumbnail).length > 0 ? [videoThumbnail] : files} single={true} dialogID="sec3_VideoThumbnail"></AttachmentUploader>
                                            </div>
                                            <div className="form-group">
                                                <img src={homepageObj.sec3_VideoThumbnail ? baseURL + homepageObj.sec3_VideoThumbnail : ""} width={150} />
                                            </div>
                                            {videoThumbnail && Object.keys(videoThumbnail).length > 0 && <button className="btn btn-primary float-right ml-2 mb-2"
                                                onClick={() => {
                                                    this.handleSectionImageUpload(videoThumbnail, "sec3_VideoThumbnail")
                                                    this.setState({ videoThumbnail: Object() })
                                                }}><i className="fa fa-save"></i> Upload Image</button>}
                                        </div>
                                    </div>


                                    {/***************Section 4***************/}
                                    <div className="col-md-12 headingDivStyle">
                                        <h5 className="headingStyle">Section 4</h5>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label htmlFor="sec4_Heading_Left">Left Heading</label>
                                                <div title="Left Heading of section 4" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10"><input maxLength={150} className="form-control"
                                            value={homepageObj.sec4_Heading_Left}
                                            onChange={(e) => this.handleHomepageChange(e)}
                                            name="sec4_Heading_Left" id="sec4_Heading_Left" placeholder="Heading"
                                        /></div>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label htmlFor="sec4_SubHeading">Sub-Heading</label>
                                                <div title="Sub-Heading of section 4" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10"><textarea className="form-control"
                                            value={homepageObj.sec4_SubHeading}
                                            onChange={(e) => this.handleHomepageChange(e)}
                                            name="sec4_SubHeading" id="sec4_SubHeading" placeholder="Sub-Heading"
                                        /></div>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label htmlFor="sec4_Heading_Right">Right Heading</label>
                                                <div title="Right Heading of section 4" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10"><input maxLength={150} className="form-control"
                                            value={homepageObj.sec4_Heading_Right}
                                            onChange={(e) => this.handleHomepageChange(e)}
                                            name="sec4_Heading_Right" id="sec4_Heading_Right" placeholder="Heading"
                                        /></div>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label className="" htmlFor="Published">Image</label>
                                                <div title="Image" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="form-group">
                                                <AttachmentUploader handleAttachedFile={(e) => {
                                                    this.handlefileAttached(e, "sec4_Image", "sec4Image")
                                                }} files={section4Image && Object.keys(section4Image).length > 0 ? [section4Image] : files} single={true} dialogID="sec4_Image"></AttachmentUploader>
                                            </div>
                                            <div className="form-group">
                                                <img src={homepageObj.sec4_Image ? baseURL + homepageObj.sec4_Image : ""} width={150} />
                                            </div>
                                            {section4Image && Object.keys(section4Image).length > 0 && <button className="btn btn-primary float-right ml-2 mb-2"
                                                onClick={() => {
                                                    this.handleSectionImageUpload(section4Image, "sec4_Image")
                                                    this.setState({ section4Image: Object() })
                                                }}><i className="fa fa-save"></i> Upload Image</button>}
                                        </div>
                                    </div>

                                    {/***************Partners Section***************/}
                                    <div className="col-md-12 partnerAddButtonDiv">
                                        <button type="button" className="btn btn-primary mb-2" onClick={() => { this.handleNewPartner() }}><i className="fa fa-plus-square p-1"></i>Add new partner</button>
                                    </div>
                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label className="" htmlFor="Published">Collaborators & Partners List</label>
                                                <div title="This List is going to show in the Collaborators & Partners section. The info is going to be displayed in respective dialog." data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="card">
                                                <div className="card-body table-responsive p-0">
                                                    <table className="table table-head-fixed table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th >No.</th>
                                                                <th >Logo</th>
                                                                <th >Heading</th>
                                                                <th >Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {partnersList && partnersList.length > 0 && partnersList.map((partner, i) => {
                                                                return <tr>
                                                                    <td>{i + 1}</td>
                                                                    <td> <img src={`${baseURL}${partner.logoColored ? partner.logoColored : ""}`} className="img-fluid" alt="partnerLogo" style={{ height: 50 }} /></td>
                                                                    <td>{partner.heading}</td>
                                                                    <td className="text-center">
                                                                        <button className="btn btn-sm btn-secondary" style={{ marginRight: "1%" }} onClick={() => {
                                                                            this.handlePartnerEditClick(partner)
                                                                        }}
                                                                        ><i className="fa fa-pen p-1"></i>Edit</button>
                                                                        <button className="btn btn-sm btn-secondary" onClick={() => {
                                                                            this.setState({ selectedPartner: partner })
                                                                            document.getElementById("btnConfirmPartnerDelete")?.click();
                                                                        }}><i className="fa fa-trash p-1"></i>Delete</button>
                                                                    </td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/***************Sponsors Section***************/}
                                    <div className="col-md-12 partnerAddButtonDiv">
                                        <button type="button" className="btn btn-primary mb-2" onClick={() => { this.handleNewSponsor() }}><i className="fa fa-plus-square p-1"></i>Add new sponsor</button>
                                    </div>
                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label className="" htmlFor="Published">Sponsors List</label>
                                                <div title="This List is going to show in the Sponsors slider" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="card">
                                                <div className="card-body table-responsive p-0">
                                                    <table className="table table-head-fixed table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th >No.</th>
                                                                <th >Logo</th>
                                                                <th >Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {sponsorsList && sponsorsList.length > 0 && sponsorsList.map((sponsor, i) => {
                                                                return <tr>
                                                                    <td>{i + 1}</td>
                                                                    <td> <img src={`${baseURL}${sponsor.logo ? sponsor.logo : ""}`} className="img-fluid" alt="sponsorLogo" style={{ height: 50 }} /></td>
                                                                    <td className="text-center">
                                                                        <button className="btn btn-sm btn-secondary" style={{ marginRight: "1%" }} onClick={() => {
                                                                            this.handleSponsorEditClick(sponsor)
                                                                        }}
                                                                        ><i className="fa fa-pen p-1"></i>Edit</button>
                                                                        <button className="btn btn-sm btn-secondary" onClick={() => {
                                                                            this.setState({ selectedSponsor: sponsor })
                                                                            document.getElementById("btnConfirmSponsorDelete")?.click();
                                                                        }}><i className="fa fa-trash p-1"></i>Delete</button>
                                                                    </td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <button id="btnPartnerForm" style={{ display: "none" }} data-toggle="modal" data-target="#partnerForm" >.</button>
                                <CustomModalDialog id="partnerForm" heading="Partner Info Form" saveButtonText="Save"
                                    onSaveButtonClick={() => {
                                        this.savePartner()
                                    }} disableSaveButton={false} >
                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label htmlFor="heading">Heading</label>
                                                <div title="Heading for partner info dialog" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <input type="text" className="form-control" id="heading" name="heading" placeholder="Heading" value={selectedPartner.heading} onChange={(e) => { this.handlePartnerChange(e) }} />
                                        </div>
                                    </div>
                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label className="" htmlFor="description">Description</label>
                                                <div title="Description for partner info dialog, 200 characters max!" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <textarea className="form-control" id="description" name="description" placeholder="Description" value={selectedPartner.description} onChange={(e) => { this.handlePartnerChange(e) }} />
                                        </div>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label className="" htmlFor="highlightedDescription">Highlighted Description</label>
                                                <div title="Highlighted description for partner info dialog. This will display in italic, 100 characters max!" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <textarea className="form-control" id="highlightedDescription" name="highlightedDescription" placeholder="Highlighted Description" value={selectedPartner.highlightedDescription} onChange={(e) => { this.handlePartnerChange(e) }} />
                                        </div>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label htmlFor="linkedIn">LinkedIn URL</label>
                                                <div title="LinkedIn URL of partner" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10"><input maxLength={150} className="form-control"
                                            value={selectedPartner.linkedIn}
                                            onChange={(e) => { this.handlePartnerChange(e) }}
                                            name="linkedIn" id="linkedIn" placeholder="LinkedIn URL"
                                        /></div>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label htmlFor="website">Website URL</label>
                                                <div title="Website URL of partner" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10"><input maxLength={150} className="form-control"
                                            value={selectedPartner.website}
                                            onChange={(e) => { this.handlePartnerChange(e) }}
                                            name="website" id="website" placeholder="Website URL"
                                        /></div>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label className="" htmlFor="logoColored">Logo (Colored)</label>
                                                <div title="Logo Colored" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="form-group">
                                                <AttachmentUploader handleAttachedFile={(e) => {
                                                    this.handlefileAttached(e, "logoColored", "partner")
                                                }} files={partnersImageColored && Object.keys(partnersImageColored).length > 0 ? [partnersImageColored] : files} single={true} dialogID="logoColored"></AttachmentUploader>
                                            </div>
                                            <div className="form-group">
                                                <img src={selectedPartner.logoColored ? baseURL + selectedPartner.logoColored : ""} width={150} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label className="" htmlFor="partnerLogo">Logo (Black & White)</label>
                                                <div title="Logo" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="form-group">
                                                <AttachmentUploader handleAttachedFile={(e) => {
                                                    this.handlefileAttached(e, "logo", "partner")
                                                }} files={partnersImage && Object.keys(partnersImage).length > 0 ? [partnersImage] : files} single={true} dialogID="logo"></AttachmentUploader>
                                            </div>
                                            <div className="form-group">
                                                <img src={selectedPartner.logo ? baseURL + selectedPartner.logo : ""} width={150} />
                                            </div>
                                        </div>
                                    </div>

                                </CustomModalDialog>

                                <button id="btnSponsorForm" style={{ display: "none" }} data-toggle="modal" data-target="#sponsorForm" >.</button>
                                <CustomModalDialog id="sponsorForm" heading="Sponsor Info Form" saveButtonText="Save"
                                    onSaveButtonClick={() => {
                                        this.saveSponsor()
                                    }} disableSaveButton={false} >
                                    <div className="form-group row form-horizontal">
                                        <div className="col-md-2 col-form-label">
                                            <div className="label-wrapper">
                                                <label className="" htmlFor="sponsorLogo">Logo</label>
                                                <div title="Logo" data-toggle="tooltip" className="ico-help"><i className="fa fa-question-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="form-group">
                                                <AttachmentUploader handleAttachedFile={(e) => {
                                                    this.handlefileAttached(e, "logo", "sponsor")
                                                }} files={sponsorsImage && Object.keys(sponsorsImage).length > 0 ? [sponsorsImage] : files} single={true} dialogID="logo"></AttachmentUploader>
                                            </div>
                                            <div className="form-group">
                                                <img src={selectedSponsor.logo ? baseURL + selectedSponsor.logo : ""} width={150} />
                                            </div>
                                        </div>
                                    </div>

                                </CustomModalDialog>

                                <button id="btnConfirmPartnerDelete" style={{ display: "none" }} data-toggle="modal" data-target="#ConfirmPartnerDelete" >.</button>
                                <ConfirmModalDialog id="ConfirmPartnerDelete" showCancelButton={true} cancelButtonText="No"
                                    confirmButtonText="Yes" confirmationText="Are you sure to delete selected partner?"
                                    heading="Confirm..."
                                    onConfirmButtonClick={() => { this.handleDeletePartner(); }} />

                                <button id="btnConfirmSponsorDelete" style={{ display: "none" }} data-toggle="modal" data-target="#ConfirmSponsorDelete" >.</button>
                                <ConfirmModalDialog id="ConfirmSponsorDelete" showCancelButton={true} cancelButtonText="No"
                                    confirmButtonText="Yes" confirmationText="Are you sure to delete selected sponsor?"
                                    heading="Confirm..."
                                    onConfirmButtonClick={() => { this.handleDeleteSponsor(); }} />

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default Homepage;
