import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from '../components/Dashboard/Dashboard';
import Login from '../components/Accounts/Login';
import Blog from '../components/Blog/Blog';
import BlogForm from '../components/Blog/BlogForm';
import Navbar from '../components/layout/navbar';
import Sidebar from '../components/layout/sidebar';
import Homepage from '../components/Homepage/Homepage';

export default function AppRoutes() {
    const isSignedIn = (localStorage.getItem("isSignedIn") && localStorage.getItem("isSignedIn") === "true") ? true : false;
    return (
        <React.Fragment>
            {isSignedIn ? (
                <div className="wrapper">
                    <Navbar />
                    <Sidebar />
                    <div className="content-wrapper">
                        <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/Dashboard" element={<Dashboard />} />
                            <Route path="/Blogs" element={<Blog />} />
                            <Route path="/BlogForm" element={<BlogForm />} />
                            <Route path="/BlogForm/:id" element={<BlogForm />} />
                            <Route path="/Homepage" element={<Homepage />} />
                        </Routes>
                    </div>
                </div>
            ) : (
                <Routes>
                    <Route path="/" element={<Login />} />
                    {/* Redirect to "/" if user is signed out */}
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            )}
        </React.Fragment>
    );
}

