import * as React from "react";

interface Props {
    id: string;
    saveButtonId?: string;
    cancelButtonId?: string;
    heading?: string;
    saveButtonText?: string;
    onSaveButtonClick?: () => void;
    onCancelButtonClick?: () => void;
    showCancelButton?: boolean;
    showSaveButton?: boolean;
    cancelButtonText?: string;
    size?: string;
    disableSaveButton?: boolean;
    showBlueHeader?: boolean;
    children?: React.ReactNode;
}

class CustomModalDialog extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

    }
    render() {
        const { id, heading, children, saveButtonText = "Save", onSaveButtonClick, onCancelButtonClick, showCancelButton, showSaveButton = true, showBlueHeader = false,
            cancelButtonText, size, disableSaveButton = false, saveButtonId, cancelButtonId } = this.props;
        const modalClass = size === "small" ? "modal-dialog modal-sm" : size === "medium" ? "modal-dialog modal-lg" : "modal-dialog modal-xl";
        return (
            <div id={id} className="modal" data-backdrop="false" role="dialog" >
                <div className={modalClass}>
                    <div className="modal-content">
                        <div className={showBlueHeader ? "modal-header blue-header" : "modal-header"}>
                            {heading && <h4 className={showBlueHeader ? "modal-title blue-header-title" : "modal-title"}>{heading}</h4>}
                            <button type="button" className="close" id="btnCloseModal" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {children}
                        </div>
                        <div className="modal-footer">
                            {showSaveButton && <button id={saveButtonId ? saveButtonId : "btnSaveDialog"} type="button" disabled={disableSaveButton} className="btn btn-primary" data-dismiss="modal" onClick={onSaveButtonClick}>{saveButtonText}</button>}
                            {showCancelButton && <button id={cancelButtonId ? cancelButtonId : "btnCancelDialog"} type="button" className="btn btn-default" data-dismiss="modal" onClick={onCancelButtonClick}>{cancelButtonText}</button>}
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}
export default CustomModalDialog;
